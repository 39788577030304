/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

import { DesktopContainer } from "./desktop_container";


/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
export const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='Hatchie Studio'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      // content=''
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
	{/* <Button primary size='huge' href="#headline">
      Get Started
      <Icon name='right arrow' />
    </Button> */}
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
    <DesktopContainer page="home">{children}</DesktopContainer>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export const HomepageLayout = () => {
  const navigate = useNavigate();

  return (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text id="headline">
        <Header as='h3' style={{ fontSize: '2em' }}>
          Bridging Disciplines
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Hatchie Studio exists at the confluence of engineering and art, bridging the divide between software development, music, graphical arts, and more to bring an enriching experience.
        </p>
      </Container>      
      <Container text>
        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Services</a>          
        </Divider>        
      </Container>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column floated='right' width={8}>
          <Header as='h3' style={{ fontSize: '2em' }}>
              Custom Software Development
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Hatchie Studio's foremost skill is in the design and development of custom software applications to fit your business need. Our talent has experience in warehouse management, ecommerce, employee onboarding, ticket management, issue tracking, learning management, document workflow, quote-claim insurance policy management.
            </p>            
            <Header as='h3' style={{ fontSize: '2em' }}>
              Game Development
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Hatchie Studio's main passion is game development. Ranging from text-based role playing games to mobile match 3, our talent has experience with Unity and RPG Maker, as well as SWR, SMAUG, and other DikuMUD derivatives.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Music
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Based in the Memphis area, we love making and producing music, from writing lyrics, for bass and guitar, to editing and mixing, through mastering and distribution for a variety of styles, including heavy metal, rock, hip-hop, and R&amp;B.
            </p>            
            {/*<Image bordered rounded size='large' src='/images/wireframe/white-image.png' />*/}
          </Grid.Column>
          <Grid.Column width={8}>

            <Header as='h3' style={{ fontSize: '2em' }}>
              World Building
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Having a love for anthropology, especially cartography and linguistics, Hatchie Studio offers services creating assets for fictional worlds for use in other media.
            </p>

            <Header as='h4'>Need a map?</Header>
            <p style={{ fontSize: '1.33em' }}>  
              Using geological and climatic models, we can create maps for your world, including fantasy style as well as heightmaps for use in 3d modelling software.
            </p>

            <Header as='h4'>Fictional language?</Header>
            <p style={{ fontSize: '1.33em' }}>
              We can build one for you, from just a few names to families of dozens of languages, from something generic fantasy sounding to alien phonologies.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              Already have a language? We will expand your corpus by writing prose and verse, myths, songs, and more or translating what you already have.
            </p>

            <Header as='h4'>Don't know where to begin? Need some feedback?</Header>
            <p style={{ fontSize: '1.33em' }}>
              We would love to review what you have, provide feedback, offer consulting, research, and more.
            </p>
          </Grid.Column>          
        </Grid.Row>
        {/*<Grid.Row>*/}
        {/*  <Grid.Column textAlign='center'>*/}
        {/*    <Button size='huge'>Check Them Out</Button>*/}
        {/*  </Grid.Column>*/}
        {/*</Grid.Row>*/}
      </Grid>      
      <Container text>
        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Projects & Case Studies</a>
        </Divider>
      </Container>              
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
          <Header as='h3' style={{ fontSize: '2em' }}>
              Project Sandstorm
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="#">Project Sandstorm</a> was an initiative to transfer hosting and support of several custom software applications from the previous provider. These applications were partly designed and developed by the talent at Hatchie Studio.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Mundifex
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://www.youtube.com/channel/UCqc_RqgOXecj411Y3tQp1cw">Mundifex</a> is a YouTube channel dedicated to computer aided world building featuring reviews of tools and resources and tutorials of the same.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              PlaTec
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://github.com/hatchie-studio/platec">PlaTec</a> is a 2D terrain generator that uses a simplified model of plate tectonics to produce realistic heightmaps in real time. Created by Lauri Viitanen and updated by Federico Tomassetti, Hatchie Studio maintains its own fork and releases updates and new features for the library under LGPLv3.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={8}>
            {/* <Header as='h3' style={{ fontSize: '2em' }}>
              Mundifex: World Creator
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://store.steampowered.com/app/47734879234723423424">Mundifex: World Creator</a> is a tool that generates a world through plate tectonics and climate simulations and allows export to a variety of formats: PNG, Unity terrain RAW, and Minecraft heightmaps.
            </p> */}
            <Header as='h3' style={{ fontSize: '2em' }}>
              Base Delta Zero
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://basedeltazero.com/">Base Delta Zero</a> is a virtual epic doom metal band with heavy Memphis blues influence.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Annals of Erthas
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://www.worldanvil.com/w/erthas-hatchie-studio">Annals of Erthas</a> is an epic fantasy setting for short stories, video games, music, and tabletop roleplaying games. Utilizing a passion for linguistics and anthropology, this world prides itself on internal consistency and realism even in a fantasy setting.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Mobile Gear
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://www.worldanvil.com/w/mobile-gear-hatchie-studio">Mobile Gear</a> is a space opera setting for video games inspired by the world of 80s and 90s mecha anime.
            </p>            
            <Header as='h3' style={{ fontSize: '2em' }}>
              Universe Glider
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="#">Universe Glider</a> is match-3 visual novel about Rocky the Asteroid and his adventure through the Solar System.
            </p>           
            {/*<Image bordered rounded size='large' src='/images/wireframe/white-image.png' />*/}
          </Grid.Column>
        </Grid.Row>
        {/*<Grid.Row>*/}
        {/*  <Grid.Column textAlign='center'>*/}
        {/*    <Button size='huge'>Check Them Out</Button>*/}
        {/*  </Grid.Column>*/}
        {/*</Grid.Row>*/}
      </Grid>            
    </Segment>

    {/*<Segment style={{ padding: '0em' }} vertical>*/}
    {/*  <Grid celled='internally' columns='equal' stackable>*/}
    {/*    <Grid.Row textAlign='center'>*/}
    {/*      <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>*/}
    {/*        <Header as='h3' style={{ fontSize: '2em' }}>*/}
    {/*          "What a Company"*/}
    {/*        </Header>*/}
    {/*        <p style={{ fontSize: '1.33em' }}>That is what they all say about us</p>*/}
    {/*      </Grid.Column>*/}
    {/*      <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>*/}
    {/*        <Header as='h3' style={{ fontSize: '2em' }}>*/}
    {/*          "I shouldn't have gone with their competitor."*/}
    {/*        </Header>*/}
    {/*        <p style={{ fontSize: '1.33em' }}>*/}
    {/*          <Image avatar src='/images/avatar/large/nan.jpg' />*/}
    {/*          <b>Nan</b> Chief Fun Officer Acme Toys*/}
    {/*        </p>*/}
    {/*      </Grid.Column>*/}
    {/*    </Grid.Row>*/}
    {/*  </Grid>*/}
    {/*</Segment>*/}

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            {/*<Grid.Column width={3}>*/}
            {/*  <Header inverted as='h4' content='About' />*/}
            {/*  <List link inverted>*/}
            {/*    /!*<List.Item as='a'>Sitemap</List.Item>*!/*/}
            {/*    /!*<List.Item as='a'>Contact Us</List.Item>*!/*/}
            {/*    /!*<List.Item as='a'>Religious Ceremonies</List.Item>*!/*/}
            {/*    /!*<List.Item as='a'>Gazebo Plans</List.Item>*!/*/}
            {/*  </List>*/}
            {/*</Grid.Column>*/}
            {/*<Grid.Column width={3}>*/}
            {/*  <Header inverted as='h4' content='Services' />*/}
            {/*  <List link inverted>*/}
	  {/* <List.Item as='a' onClick={() => navigate('/custom-software')}>Custom Software</List.Item>*/}
            {/*    <List.Item as='a'>DNA FAQ</List.Item>*/}
            {/*    <List.Item as='a'>How To Access</List.Item>*/}
            {/*    <List.Item as='a'>Favorite X-Men</List.Item>*/}
             {/* </List>*/}
            {/*</Grid.Column>*/}
            {/*<Grid.Column width={7}>*/}
            {/*  <Header as='h4' inverted>*/}
            {/*    Footer Header*/}
            {/*  </Header>*/}
            {/*  <p>*/}
            {/*    Extra space for a call to action inside the footer that could help re-engage users.*/}
            {/*  </p>*/}
            {/*</Grid.Column>*/}
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
  )
}

