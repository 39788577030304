/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {Container, Menu, Segment, Visibility} from "semantic-ui-react";
import {HomepageHeading} from "./homepage_layout";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const MenuItem = (props) => {
  return (
    <Menu.Item as='a' href={props.href} active={props.page == props.page}>
        {props.children}
    </Menu.Item>
  )
}

export class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <div>
          <Segment
            inverted
            textAlign='center'
            style={{
              // minHeight: 700,
              padding: '1em 0em'
            }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <MenuItem href={'/'} page={this.props.page}>Home</MenuItem>
                {/*<Menu.Item position='right'>*/}
                {/*  <Button as='a' inverted={!fixed}>*/}
                {/*    Log in*/}
                {/*  </Button>*/}
                {/*  <Button as='a' inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }}>*/}
                {/*    Sign Up*/}
                {/*  </Button>*/}
                {/*</Menu.Item>*/}
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>


        {children}
      </div>
    )
  }
}

DesktopContainer.propTypes = {
  page: PropTypes.string,
  children: PropTypes.node,
}

