/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

import { DesktopContainer } from "./desktop_container";


/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
export const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='Hatchie Studio'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content=''
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
	{/*<Button primary size='huge'>
      Get Started
      <Icon name='right arrow' />
    </Button>*/}
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
    <DesktopContainer page="home">{children}</DesktopContainer>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export const ServicesLayout = () => {
  const navigate = useNavigate();

  return (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Mundifex
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://www.youtube.com/channel/UCqc_RqgOXecj411Y3tQp1cw">Mundifex</a> is a YouTube channel dedicated to computer aided world building featuring reviews of tools and resources and tutorials of the same.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              PlaTec
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://github.com/hatchie-studio/platec">PlaTec</a> is a 2D terrain generator that uses a simplified model of plate tectonics to produce realistic heightmaps in real time. Created by Lauri Viitanen and updated by Federico Tomassetti, Hatchie Studio maintains its own fork and releases updates and new features for the library under LGPLv3.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              uTectonics
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://u3d.as/2SnP">uTectonics</a> is a Unity plugin that allows users to generate a terrain using a plate tectonics simulation powered by PlaTec.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Mundifex: World Creator
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://store.steampowered.com/app/47734879234723423424">Mundifex: World Creator</a> is a tool that generates a world through plate tectonics and climate simulations and allows export to a variety of formats: PNG, Unity terrain RAW, and Minecraft heightmaps.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Ciryatur
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://ciryatur.bandcamp.com/">Ciryatur</a> is a virtual epic doom metal band with heavy Memphis blues influence. Production on their debut commenced in 2022.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Annals of Eros
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a href="https://www.worldanvil.com/w/eros-drozel">Annals of Eros</a> is an epic fantasy setting for short stories, video games, music, and tabletop roleplaying games.
            </p>
            {/*<Image bordered rounded size='large' src='/images/wireframe/white-image.png' />*/}
          </Grid.Column>
        </Grid.Row>
        {/*<Grid.Row>*/}
        {/*  <Grid.Column textAlign='center'>*/}
        {/*    <Button size='huge'>Check Them Out</Button>*/}
        {/*  </Grid.Column>*/}
        {/*</Grid.Row>*/}
      </Grid>
    </Segment>

    {/*<Segment style={{ padding: '0em' }} vertical>*/}
    {/*  <Grid celled='internally' columns='equal' stackable>*/}
    {/*    <Grid.Row textAlign='center'>*/}
    {/*      <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>*/}
    {/*        <Header as='h3' style={{ fontSize: '2em' }}>*/}
    {/*          "What a Company"*/}
    {/*        </Header>*/}
    {/*        <p style={{ fontSize: '1.33em' }}>That is what they all say about us</p>*/}
    {/*      </Grid.Column>*/}
    {/*      <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>*/}
    {/*        <Header as='h3' style={{ fontSize: '2em' }}>*/}
    {/*          "I shouldn't have gone with their competitor."*/}
    {/*        </Header>*/}
    {/*        <p style={{ fontSize: '1.33em' }}>*/}
    {/*          <Image avatar src='/images/avatar/large/nan.jpg' />*/}
    {/*          <b>Nan</b> Chief Fun Officer Acme Toys*/}
    {/*        </p>*/}
    {/*      </Grid.Column>*/}
    {/*    </Grid.Row>*/}
    {/*  </Grid>*/}
    {/*</Segment>*/}

    {/*<Segment style={{ padding: '8em 0em' }} vertical>*/}
    {/*  <Container text>*/}
    {/*    <Header as='h3' style={{ fontSize: '2em' }}>*/}
    {/*      Breaking The Grid, Grabs Your Attention*/}
    {/*    </Header>*/}
    {/*    <p style={{ fontSize: '1.33em' }}>*/}
    {/*      Instead of focusing on content creation and hard work, we have learned how to master the*/}
    {/*      art of doing nothing by providing massive amounts of whitespace and generic content that*/}
    {/*      can seem massive, monolithic and worth your attention.*/}
    {/*    </p>*/}
    {/*    <Button as='a' size='large'>*/}
    {/*      Read More*/}
    {/*    </Button>*/}

    {/*    <Divider*/}
    {/*      as='h4'*/}
    {/*      className='header'*/}
    {/*      horizontal*/}
    {/*      style={{ margin: '3em 0em', textTransform: 'uppercase' }}*/}
    {/*    >*/}
    {/*      <a href='#'>Case Studies</a>*/}
    {/*    </Divider>*/}

    {/*    <Header as='h3' style={{ fontSize: '2em' }}>*/}
    {/*      Did We Tell You About Our Bananas?*/}
    {/*    </Header>*/}
    {/*    <p style={{ fontSize: '1.33em' }}>*/}
    {/*      Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but*/}
    {/*      it's really true. It took years of gene splicing and combinatory DNA research, but our*/}
    {/*      bananas can really dance.*/}
    {/*    </p>*/}
    {/*    <Button as='a' size='large'>*/}
    {/*      I'm Still Quite Interested*/}
    {/*    </Button>*/}
    {/*  </Container>*/}
    {/*</Segment>*/}

  </ResponsiveContainer>
  )
}

