
import logo from './logo.svg';
import './App.css';
import { HomepageLayout } from "./pages/homepage_layout";
import { ServicesLayout } from "./pages/services_layout";
import { UGPrivacyLayout } from "./pages/ug_privacy_policy";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomepageLayout/>}/>
        <Route path="/services" element={<ServicesLayout/>}/>
        <Route path="/universe_glider/privacy_policy" element={<UGPrivacyLayout/>}/>
      </Routes>
    </Router>
  );
}

export default App;
