/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

import { DesktopContainer } from "./desktop_container";


/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
export const UGPrivacyHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='Hatchie Studio'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      // content=''
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
	{/* <Button primary size='huge' href="#headline">
      Get Started
      <Icon name='right arrow' />
    </Button> */}
  </Container>
)

UGPrivacyHeading.propTypes = {
  mobile: PropTypes.bool,
}

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
    <DesktopContainer page="home">{children}</DesktopContainer>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export const UGPrivacyLayout = () => {
  const navigate = useNavigate();

  return (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>  
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={16}>
          <h1>Privacy Policy</h1>

    <p><strong>Last updated: March 15, 2024</strong></p>

    <p>This Privacy Policy outlines our policies and procedures regarding the collection, use, and disclosure of your information when you use the Service. It also informs you about your privacy rights and how the law protects you. Importantly, this privacy policy is unique because our Service does not collect or use any of your personal data.</p>

    <h2>Interpretation and Definitions</h2>
    
    <p>The words with the initial letter capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

    <h3>Definitions:</h3>
    <ul>
        <li><strong>Account</strong> means a unique account created for you to access our Service or parts of our Service.</li>
        <li><strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party.</li>
        <li><strong>Application</strong> refers to Universe Glider, the software program provided by the Company.</li>
        <li><strong>Company</strong> (referred to as either "the Company," "We," "Us," or "Our" in this Agreement) refers to Hatchie Studio, LLC.</li>
        <li><strong>Country</strong> refers to: Tennessee, United States.</li>
        <li><strong>Device</strong> means any device that can access the Service, such as a computer, a cellphone, or a digital tablet.</li>
        <li><strong>Service</strong> refers to the Application.</li>
        <li><strong>Service Provider</strong> means any natural or legal person who processes data on behalf of the Company.</li>
        <li><strong>Usage Data</strong> refers to data that is collected automatically (this is not applicable since our Service does not collect data).</li>
        <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service.</li>
    </ul>

    <h2>No Collection or Use of Personal Data</h2>
    
    <p>Our Service does not collect any Personal Data. This means we do not collect information that can be used to contact or identify you. As such, the usual sections regarding Types of Data Collected, Use of Your Personal Data, and Transfer of Your Personal Data are not applicable to our Service.</p>

    <h2>Security of Your Data</h2>
    
    <p>Since we do not collect any Personal Data, the security concerns related to the transmission and storage of your data are not applicable to our Service.</p>

    <h2>Children's Privacy</h2>
    
    <p>Our Service does not address anyone under the age of 13, nor does it collect information from anyone under the age of 13.</p>

    <h2>Links to Other Websites</h2>
    
    <p>Our Service may contain links to other websites not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

    <h2>Changes to this Privacy Policy</h2>
    
    <p>We may update our Privacy Policy from time to time. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

    <h2>Contact Us</h2>
    
    <p>If you have any questions about this Privacy Policy, please contact us by email: <a href="mailto:support@hatchie.studio">support@hatchie.studio</a>.</p>

          </Grid.Column>          
        </Grid.Row>
        {/*<Grid.Row>*/}
        {/*  <Grid.Column textAlign='center'>*/}
        {/*    <Button size='huge'>Check Them Out</Button>*/}
        {/*  </Grid.Column>*/}
        {/*</Grid.Row>*/}
      </Grid>                
    </Segment>

    {/*<Segment style={{ padding: '0em' }} vertical>*/}
    {/*  <Grid celled='internally' columns='equal' stackable>*/}
    {/*    <Grid.Row textAlign='center'>*/}
    {/*      <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>*/}
    {/*        <Header as='h3' style={{ fontSize: '2em' }}>*/}
    {/*          "What a Company"*/}
    {/*        </Header>*/}
    {/*        <p style={{ fontSize: '1.33em' }}>That is what they all say about us</p>*/}
    {/*      </Grid.Column>*/}
    {/*      <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>*/}
    {/*        <Header as='h3' style={{ fontSize: '2em' }}>*/}
    {/*          "I shouldn't have gone with their competitor."*/}
    {/*        </Header>*/}
    {/*        <p style={{ fontSize: '1.33em' }}>*/}
    {/*          <Image avatar src='/images/avatar/large/nan.jpg' />*/}
    {/*          <b>Nan</b> Chief Fun Officer Acme Toys*/}
    {/*        </p>*/}
    {/*      </Grid.Column>*/}
    {/*    </Grid.Row>*/}
    {/*  </Grid>*/}
    {/*</Segment>*/}

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            {/*<Grid.Column width={3}>*/}
            {/*  <Header inverted as='h4' content='About' />*/}
            {/*  <List link inverted>*/}
            {/*    /!*<List.Item as='a'>Sitemap</List.Item>*!/*/}
            {/*    /!*<List.Item as='a'>Contact Us</List.Item>*!/*/}
            {/*    /!*<List.Item as='a'>Religious Ceremonies</List.Item>*!/*/}
            {/*    /!*<List.Item as='a'>Gazebo Plans</List.Item>*!/*/}
            {/*  </List>*/}
            {/*</Grid.Column>*/}
            {/*<Grid.Column width={3}>*/}
            {/*  <Header inverted as='h4' content='Services' />*/}
            {/*  <List link inverted>*/}
	  {/* <List.Item as='a' onClick={() => navigate('/custom-software')}>Custom Software</List.Item>*/}
            {/*    <List.Item as='a'>DNA FAQ</List.Item>*/}
            {/*    <List.Item as='a'>How To Access</List.Item>*/}
            {/*    <List.Item as='a'>Favorite X-Men</List.Item>*/}
             {/* </List>*/}
            {/*</Grid.Column>*/}
            {/*<Grid.Column width={7}>*/}
            {/*  <Header as='h4' inverted>*/}
            {/*    Footer Header*/}
            {/*  </Header>*/}
            {/*  <p>*/}
            {/*    Extra space for a call to action inside the footer that could help re-engage users.*/}
            {/*  </p>*/}
            {/*</Grid.Column>*/}
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
  )
}

